import queryString from 'query-string';
import _times from 'lodash/times';
import type { CriteriaForm as Criteria } from '../../app/reducers/hotel/hotelSearch';
type QuerystringParamsType = {
  startDate: string;
  endDate: string;
  /**
   * @deprecated
   */
  adults: string;
  /**
   * @deprecated
   */
  children: string;
  /**
   * @deprecated
   */
  childrenAges: string[];
  credentialIds: string[];
  roomRateIds: string[];
  promoCode: string;
  cityIds: string[];
  hotelIds: string[];
  destinationName: string | string[];
  propertyId: string;
  contentType: 'property' | 'region';
  clientId: string;
  clientName: string;
  enablePromoCode: string;
  rooms: string[];
  quotationId?: string;
  personId?: string;
  personName?: string;
  previousOrderId?: string;
  couponCode?: string;
  personTSER_idContrato?: number;
};
type Occupancy = {
  adults: number;
  children?: number;
  childrenAges?: number[];
};
export const stringifyOccupancy = (occupancy: Occupancy): string => {
  const {
    adults,
    children,
    childrenAges
  } = occupancy;
  return `a${adults}${children > 0 ? 'c' : ''}${children > 0 ? _times(children, idx => childrenAges?.[idx] ?? '0').join(',') : ''}`;
};
const parseRoom = (text: string): Occupancy => {
  if (text?.indexOf('a') == 0) {
    const indexOfC = text?.indexOf('c');
    const adults = parseInt(text.slice(1, indexOfC > 0 ? indexOfC : undefined));
    const childrenAges = indexOfC > 0 ? text?.slice(indexOfC + 1).split(',').map(r => parseInt(r || '0')).map(r => isNaN(r) ? 0 : r) : [];
    const children = childrenAges?.length ?? 0;
    return {
      adults,
      childrenAges,
      children
    };
  }
  return null;
};

/**
 * Usado para tratar querystring sem brackets #204493
 */
const PARAMS_ARRAY: ReadonlyArray<keyof QuerystringParamsType> = ['hotelIds', 'childrenAges', 'credentialIds', 'roomRateIds', 'cityIds', 'rooms'];
export const parseQueryString = (search: string = location.search): Criteria => {
  const params = queryString.parse(search, {
    arrayFormat: 'bracket'
  });
  for (const x of PARAMS_ARRAY) {
    let params2;
    if (params[x] && typeof params[x] === 'string') {
      // querystring não veio com arrayFormat: 'bracket' #204493
      params2 = params2 ?? queryString.parse(search);
      params[x] = typeof params2[x] == 'string' ? [params2[x]].filter(Boolean) : params2[x];
    }
  }
  const {
    startDate,
    endDate,
    adults,
    children,
    childrenAges,
    credentialIds,
    roomRateIds,
    promoCode,
    cityIds,
    hotelIds,
    destinationName,
    propertyId,
    contentType,
    clientId,
    clientName,
    enablePromoCode,
    rooms,
    quotationId,
    personId,
    personName,
    couponCode,
    personTSER_idContrato
  } = ((params as unknown) as QuerystringParamsType);
  const criteria: Criteria = {
    startDate,
    endDate,
    destinations: cityIds || hotelIds || propertyId || contentType ? {
      cityIds,
      hotelIds,
      propertyId,
      contentType
    } : undefined,
    rooms: rooms && rooms?.length > 0 ? rooms.map(parseRoom) : adults || children || childrenAges ? [
    // TODO Suporte múltiplos quartos
    {
      adults: adults && parseInt((adults as string)) || 0,
      children: children && parseInt((children as string)) || 0,
      childrenAges: childrenAges && (childrenAges as string[]).map(x => parseInt(x)) || []
    }] : undefined,
    credentialIds,
    roomRateIds,
    promoCode,
    destinationName,
    clientId,
    personId,
    personName,
    clientName: clientName,
    enablePromoCode: enablePromoCode == null ? undefined : Boolean(enablePromoCode),
    quotationId,
    couponCode,
    personTSER_idContrato
  };
  Object.keys(criteria).forEach(k => {
    if (criteria[k] === undefined) delete criteria[k];
  });
  if (Object.values(criteria).filter(x => x !== undefined).length > 0) return criteria;else return null;
};
export const generateQueryString = (criteria: Criteria): string => {
  const params: QuerystringParamsType = criteria ? {
    startDate: criteria.startDate || undefined,
    endDate: criteria.endDate || undefined,
    cityIds: criteria.destinations && criteria.destinations.cityIds || undefined,
    hotelIds: criteria.destinations && criteria.destinations.hotelIds || undefined,
    propertyId: criteria.destinations?.propertyId || undefined,
    contentType: criteria.destinations?.contentType || undefined,
    credentialIds: criteria.credentialIds || undefined,
    roomRateIds: criteria.roomRateIds || undefined,
    promoCode: criteria.promoCode || undefined,
    destinationName: criteria.destinationName || undefined,
    rooms: criteria.rooms?.map(stringifyOccupancy) || undefined,
    adults: criteria.rooms?.[0]?.adults?.toString() || undefined,
    // TODO múltiplos quartos
    children: criteria.rooms?.[0]?.children?.toString() || undefined,
    childrenAges: criteria.rooms?.[0]?.childrenAges?.map(r => r?.toString()) || undefined,
    clientId: criteria.clientId,
    clientName: criteria.clientName,
    enablePromoCode: criteria.enablePromoCode?.toString(),
    quotationId: criteria?.quotationId,
    personId: criteria?.personId,
    personName: criteria?.personName,
    couponCode: criteria?.couponCode,
    personTSER_idContrato: criteria?.personTSER_idContrato
  } : undefined;
  return params && queryString.stringify(params, {
    arrayFormat: 'bracket'
  });
};
export const highlightedURL = (url: string) => {
  const highlightParams = url => {
    const [base, params] = url.split('#');
    if (!params) return url;
    const highlightedParams = params.split('&').map(param => {
      const [key, value] = param.split('=');
      return `${key}<b>=${decodeURIComponent(value)}</b>`;
    }).join('&');
    return `${base}#${highlightedParams}`;
  };
  const highlightedURL = highlightParams(url);
  return highlightedURL;
};