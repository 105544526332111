import { Button } from '@mui/material'
import styled from 'styled-components'
import { BREAKPOINT_SM } from '../../theme/ota'

interface ContainerProps {
  status?: boolean
  bgColor?: string
}

export const Container = styled.div<ContainerProps>``

export type SelectPropsColor = {
  wide?: boolean | 'wide' | 'normal'
  bgColorButton?: string
  colorTextButton?: string
  highlighted?: boolean | any
}

export const Select = styled.select<SelectPropsColor>`
  border: 1px solid ${({ theme }) => theme.colors?.gray4};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors?.black};
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  padding: 6px;
  height: 36px;
  width: ${({ wide }) => (wide ? '100%' : '120px')};

  @media (max-width: calc(${BREAKPOINT_SM})) {
    display: none;
  }

  option {
    padding: 2px;
    &:hover {
      background-color: ${({ theme }) => theme.colors?.primary};
    }
  }
`

interface CounterProps {
  typeCounter?: boolean
}
export const Counter = styled.div<CounterProps>`
  display: ${({ typeCounter }) => (typeCounter ? 'flex' : 'none')};

  @media (max-width: calc(${BREAKPOINT_SM})) {
    display: flex;
  }
`
export const TextCounter = styled.p`
  font-size: 12px;
  font-weight: 700;
  padding-bottom: 4px;
  text-align: center;
`

export const Btn = styled(Button).attrs<SelectPropsColor>(({ highlighted = false }) => ({
  variant: highlighted ? 'contained' : 'outlined',
  color: highlighted ? 'primary' : 'textColor',
}))<SelectPropsColor>`
  display: flex;
  align-items: center;
  justify-content: baseline;
  gap: 10px;
  &&& {
    width: ${({ wide }) => (wide ? '100%' : '113px')};
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
