import type { LegacyPaymentOption, PaymentOption, PriceComponent } from '@niarab2c/frontend-commons/src/types/hotels';
import { parseISO } from 'date-fns';
import _groupBy from 'lodash/groupBy';
import _pick from 'lodash/pick';
import * as React from 'react';
import { IcNoImageCart } from '../../Icons';
import { ToolTip } from '../../microComponents';
import { CartItemsProps } from './CartItems';
import CartPeriod from './CartPeriod';
import CartRoom from './CartRoom/CartRoom';
import HotelCartTitle from './CartTitle';
import HotelCartTotal, { CartTotalProps } from './CartTotal';
import * as S from './styles';
export type HotelCartProps = {
  cartItems: CartItemsProps['items'];
  cartTotal: CartTotalProps['cartTotal'];
  paymentInfo?: CartTotalProps['paymentInfo'];
  paymentOptions?: LegacyPaymentOption[] | PaymentOption[];
  onCheckout: CartTotalProps['onCheckout'];
  onCreateQuotation?: CartTotalProps['onCreateQuotation'];
  comparison?: {
    type: string;
    percentage: number;
    price: PriceComponent;
  };
  showComissionAndMarkup?: 'tenant' | 'client' | false | null;
  setQuantity?: (value: number) => void;
  quantity?: number;
  maxQuantity?: number;
};
const HotelCart: React.FC<HotelCartProps> = ({
  cartItems,
  cartTotal,
  paymentInfo,
  paymentOptions,
  onCheckout,
  comparison,
  showComissionAndMarkup,
  onCreateQuotation,
  setQuantity,
  quantity,
  maxQuantity
}) => {
  const groupedCartItems = React.useMemo(() => Object.values(_groupBy(cartItems, cartItem => JSON.stringify(_pick(cartItem, 'hotelId', 'startDate', 'endDate')))), [cartItems]);
  return <>
      <S.CartCard>
        <S.CardContent>
          <HotelCartTitle />
          {groupedCartItems?.length > 0 ? groupedCartItems?.map((group, idx) => {
          const firstCartItem = group[0];
          const formattedStartDate = parseISO(firstCartItem?.startDate);
          const formattedEndDate = parseISO(firstCartItem?.endDate);
          return <S.ContentCart key={idx}>
                  <S.GroupCartItem>
                    <S.IconRoom src={group[0]?.roomImage ?? IcNoImageCart} alt="" />
                    <div style={{
                width: '100%'
              }}>
                      <ToolTip title={<S.HeaderHotelName>{firstCartItem.hotelName}</S.HeaderHotelName>}>
                        <S.HotelTitle>{firstCartItem.hotelName}</S.HotelTitle>
                      </ToolTip>

                      {firstCartItem.cityName && <S.City>
                          <S.TextCity>{firstCartItem.cityName}</S.TextCity>
                        </S.City>}
                      <CartPeriod startDate={formattedStartDate} endDate={formattedEndDate} dataTestId="hotel-card-period" disableTotalNight />

                      {group.map((cartItem, index) => <S.ContainerItems key={index}>
                          <S.Item>
                            <CartRoom {...cartItem} showOnlyTotal disableTax maxQuantity={maxQuantity} quantity={quantity} setQuantity={setQuantity} policyDescription={cartItem?.roomRates?.[0]?.descriptions?.cancelPolicy} showComissionAndMarkup={showComissionAndMarkup} />
                          </S.Item>
                        </S.ContainerItems>)}
                    </div>
                  </S.GroupCartItem>
                </S.ContentCart>;
        }) : <>
              <S.Divider />
              <S.EmptyCartMessageContainer>
                <p>Selecione quartos da lista ao lado para reservar.</p>
              </S.EmptyCartMessageContainer>
            </>}
        </S.CardContent>
        {cartTotal?.value > 0 && <HotelCartTotal cartTotal={cartTotal} paymentInfo={paymentInfo} paymentOptions={paymentOptions} comparison={comparison} onCheckout={onCheckout} onCreateQuotation={onCreateQuotation} />}
      </S.CartCard>
    </>;
};
export default HotelCart;