import styled from 'styled-components'
import type { ThemeColor } from '../../theme'
import { BREAKPOINT_SM, BREAKPOINT_MD } from '../../theme/ota'

interface BadgeProps {
  $borderRadius?: string
  $borderRadiusMb?: string
  $color: ThemeColor | string
  $colorText?: ThemeColor | string
  $fontSize?: string
  $fontWeight?: string
  $fullMobile?: boolean
  $fullTablet?: boolean
  $height?: string
  $gap?: string
  $padding?: string
}

export const Container = styled.div<BadgeProps>`
  font-family: 'Manrope';
  align-items: center;
  background-color: ${({ $color, theme }) => theme?.colors?.[$color] ?? $color};
  border-radius: ${({ $borderRadius }) => $borderRadius ?? '20px'};
  color: ${({ $colorText }) => $colorText ?? '#fff'};
  display: inline-flex;
  font-size: ${({ $fontSize }) => $fontSize ?? '11px'};
  font-weight: ${({ $fontWeight }) => $fontWeight ?? 600};
  height: ${({ $height }) => $height ?? '20px'};
  justify-content: center;
  line-height: 18px;
  margin: 0;
  gap: ${({ $gap }) => $gap ?? '0px'};
  min-width: 24px;
  padding: ${({ $padding }) => $padding ?? '0 7px'};
  text-decoration: none;
  white-space: nowrap;

  @media (max-width: calc(${BREAKPOINT_SM})) {
    border-radius: ${({ $borderRadiusMb }) => $borderRadiusMb ?? '11px'};
    font-size: ${({ $fullMobile }) => ($fullMobile ? '13px' : '11px')};
    height: ${({ $height, $fullMobile }) => $height ?? ($fullMobile ? '40px' : '22px')};
    ${({ $fullMobile }) => ($fullMobile ? 'width: 100%;' : '')}
  }

  @media (max-width: calc(${BREAKPOINT_MD})) {
    border-radius: ${({ $borderRadiusMb }) => $borderRadiusMb ?? '11px'};
    font-size: ${({ $fullTablet }) => ($fullTablet ? '13px' : '11px')};
    height: ${({ $height, $fullTablet }) => $height ?? ($fullTablet ? '40px' : '22px')};
    ${({ $fullTablet }) => ($fullTablet ? 'width: 100%;' : '')}
  }
`
