import type { EngineRuleRoomRate, EntitiesDataProps, HotelDetails, HotelResult, MetasearchResult, Occupancy, OriginalHotelResult, OriginalRoomRate, RoomRate } from '@niarab2c/frontend-commons/src/types/hotels';
import { distanceLatLon } from '@niaratech/niara-js-commons';
import shallowEqual from 'fbjs/lib/shallowEqual';
import _omit from 'lodash/omit';
import _uniq from 'lodash/uniq';
import normalizeMealName from '../normalizeMealName';
import { calculateRemuneration, groupRoomRates, isSame, roomRateSorter } from './index';
const ROOM_RATES_KEYS = (['roomRates', 'notAvailableRoomRates'] as const);
/**
 * Transforma um OriginalHotelResult em um HotelResult:
 * - preenche campos complementares em cada roomRate, como credential, hotelId, occupancy, promocode (normaliza room rates)
 * - determina melhor preço, melhor remuneração, agrupa room rates, etc (analyzeBestRoomRates)
 * - se passar oldHR, faz merge
 *
 * @param hotelResult
 * @param oldHR
 * @param params
 * @returns
 */

export function prepareHotelResult(hotelResult: Partial<OriginalHotelResult> | Partial<HotelResult>, oldHR?: null | HotelResult, params?: {
  occupancy?: Occupancy;
  referencePoint?: {
    latitude;
    longitude;
  };
  entitiesData?: EntitiesDataProps;
  promoCode?: string;
  clientId?: string;
  selfBooking: boolean;
  b2c: boolean;
  personBalance?: number;
  personContractStatus?: string;
  engineRuleVersion: string;
  manualReservationId?: string;
  quotationToken?: string;
  quotationIndex?: number;
}): HotelResult {
  let result: Partial<HotelResult> = ({
    ...hotelResult,
    clientId: params?.clientId ?? hotelResult['clientId'] ?? oldHR?.['clientId']
  } as Partial<HotelResult>);
  ROOM_RATES_KEYS.forEach(key => {
    if (hotelResult[key] && hotelResult[key].find((rr: OriginalRoomRate | RoomRate) => !rr['_normalizedRoomRate'])) {
      // normalize room rates
      result[key] = result[key].map<any>(_rr => {
        if (_rr['_normalizedRoomRate']) {
          // já foi normalizado!
          return _rr;
        }
        const originalRoomRate: OriginalRoomRate = ((_rr as unknown) as OriginalRoomRate);
        const roomRate: RoomRate = ({
          ..._rr,
          _normalizedRoomRate: true
        } as RoomRate);
        // garante roomRate.available
        roomRate.available = key === 'notAvailableRoomRates' ? false : true;
        // copia credential, occupancy e hotelId para cada roomRate
        roomRate.credential = hotelResult.credential;
        roomRate.hotelId = hotelResult.hotel.id; // usar hotelResult.hotel.id ao invés de result._id - é o id do hotel que originou a resposta
        roomRate.occupancy = params?.occupancy ?? roomRate?.occupancy;
        roomRate.promoCode = params?.promoCode ?? roomRate?.promoCode;
        roomRate.engineRuleVersion = (params?.engineRuleVersion as '0' | '1') ?? roomRate?.engineRuleVersion ?? '0';
        roomRate.manualReservationId = params?.manualReservationId ?? roomRate?.manualReservationId;
        roomRate.quotationToken = params?.quotationToken ?? roomRate?.quotationToken;
        roomRate.quotationIndex = params?.quotationIndex ?? roomRate?.quotationIndex;
        const selfBooking = params?.selfBooking;
        const b2c = params?.b2c;
        const personBalance = params?.personBalance;
        // const personContractStatus = params?.personContractStatus
        // transforma o loyaltyRedeem no slot.loyaltyRedeem
        const loyaltyRedeems = roomRate.engineRuleVersion == '1' ? originalRoomRate.loyaltyRedeems?.map(lr => {
          const loyaltyRedeemConfiguration = params?.entitiesData?.loyaltyRedeemRules?.[lr.id];
          const newLr: EngineRuleRoomRate['loyaltyRedeems'][number] = Object.assign(_omit(lr, 'minRemainingValue', 'remainingValue'), {
            fractionDigits: loyaltyRedeemConfiguration?.fractionDigits,
            type: loyaltyRedeemConfiguration?.type,
            unitText: loyaltyRedeemConfiguration?.unitText,
            highlightText: loyaltyRedeemConfiguration?.highlightText,
            informativeText: loyaltyRedeemConfiguration?.informativeText,
            moneyPointsOrder: loyaltyRedeemConfiguration?.moneyPointsOrder,
            remainingPrice: lr.remainingValue != null ? {
              value: lr.remainingValue,
              currency: roomRate.priceComposition?.total?.currency
            } : null,
            b2cAuthenticationRequired: loyaltyRedeemConfiguration.b2cAuthenticationRequired
          });
          return newLr;
        }) : originalRoomRate.businessRules?.map(br => br.loyaltyRedeems).flat(1)?.map(legacyLR => {
          const newLr: EngineRuleRoomRate['loyaltyRedeems'][number] = Object.assign(_omit(legacyLR, 'minRemainingValue'), {
            minRemainingPrice: legacyLR.minRemainingValue != null ? {
              value: legacyLR.minRemainingValue,
              currency: roomRate.priceComposition?.total?.currency
            } : null
          });
          return newLr;
        });
        roomRate.loyaltyRedeems = loyaltyRedeems;
        /** se personBalance não é nulo e todos os loyaltyredeems tem minPointsToBurn maior que o personBalance */
        if (personBalance != null && personBalance > 0 && loyaltyRedeems?.length > 0 /* ver bug #176798 */ && loyaltyRedeems?.every(redeem => redeem.minPointsToBurn > 0 && redeem.minPointsToBurn > personBalance)) {
          roomRate.available = false;
          roomRate.availabilityRestrictions = roomRate.availabilityRestrictions || [];
          roomRate.availabilityRestrictions.push({
            reasonMessage: "Não há pontos suficientes na carteira do hóspede"
          });
        }
        if (roomRate.engineRuleVersion != '1' && roomRate.payment?.paymentOptions?.find(o => o.extras) && roomRate.payment?.extras?.length > 0) {
          roomRate.payment.paymentOptions = roomRate.payment.paymentOptions.map(option => {
            if (option.extras) {
              return {
                ...option,
                extraOptions: roomRate.payment?.extras?.map(e => ({
                  id: e.id?.toString(),
                  type: e.type
                }))
              };
            } else {
              return option;
            }
          });
        }
        if (params?.entitiesData && originalRoomRate.metasearch) {
          const metasearchRules = params.entitiesData.metaSearchRules?.[roomRate.metasearch.id];
          roomRate.metasearch = {
            ...originalRoomRate.metasearch,
            slots: originalRoomRate.metasearch.slots?.map(originalSlot => {
              const metasearchRulesSlot = metasearchRules.slots?.find(slot => slot.id === originalSlot.id);
              const loyaltyRedeemRule = params.entitiesData.loyaltyRedeemRules?.[originalSlot?.loyaltyRedeem?.id];
              const loyaltyRedeem: RoomRate['metasearch']['slots'][0]['loyaltyRedeem'] = originalSlot?.loyaltyRedeem ? {
                ...originalSlot?.loyaltyRedeem,
                unitText: loyaltyRedeemRule?.unitText
              } : null;
              const loyaltyRewardRule = params.entitiesData.loyaltyRewardRules?.[originalSlot?.loyaltyReward?.id];
              const loyaltyReward: RoomRate['metasearch']['slots'][0]['loyaltyReward'] = originalSlot?.loyaltyReward ? {
                ...originalSlot.loyaltyReward,
                unitText: loyaltyRewardRule?.unitText,
                cashback: loyaltyRewardRule.loyaltyType === 'CASHBACK' ? {
                  value: originalSlot.loyaltyReward.value,
                  currency: originalSlot.loyaltyReward.currency
                } : undefined,
                points: loyaltyRewardRule.loyaltyType === 'POINTS' ? {
                  value: originalSlot.loyaltyReward.value
                } : undefined,
                highlightText: loyaltyRewardRule?.highlightText,
                informativeText: loyaltyRewardRule?.informativeText
              } : null;
              return ({
                ...metasearchRulesSlot,
                ...originalSlot,
                loyaltyRedeem,
                loyaltyReward,
                badgeText: metasearchRulesSlot.badgeEnabled ? metasearchRulesSlot.badgeText : undefined,
                paymentOptions: metasearchRulesSlot.ctaAction == 'NONE' || !metasearchRulesSlot.ctaAction ? null : roomRate.engineRuleVersion === '1' ? roomRate.paymentOptions : roomRate?.payment?.paymentOptions,
                ...(roomRate.promotion && (metasearchRulesSlot.sourceType === 'SEARCH' || metasearchRulesSlot.sourceType === 'ENGINE_RULE') ? {
                  promotion: roomRate.promotion
                } : {})
              } as MetasearchResult['slots'][number]);
            })
          };
        }

        //calcula o productPrice (removendo as taxas do total value)
        if (roomRate.priceComposition?.total?.value != null) {
          const priceComposition = {
            ...roomRate.priceComposition
          };
          if (priceComposition.taxes?.value != null) {
            priceComposition.productPrice = {
              value: priceComposition?.total?.value - priceComposition?.taxes?.value,
              currency: priceComposition?.total?.currency
            };
          } else {
            priceComposition.productPrice = {
              value: priceComposition?.total?.value,
              currency: priceComposition?.total?.currency
            };
          }
          roomRate.priceComposition = priceComposition;

          //calcula a remuneração - TODO - calcular a remuneração diferente se for usuário do cliente
          roomRate._remuneration = b2c ? undefined : calculateRemuneration(roomRate, selfBooking) ?? roomRate._remuneration;
        } else {
          //Se não tiver total no priceComposition, apagar o priceComposition.
          roomRate.priceComposition = null;
        }

        //preparar propriedades que serão usadas em filtros
        roomRate._cancelPolicy = roomRate.cancelPolicy.nonRefundable ? 'NON_REFUNDABLE' : roomRate.cancelPolicy.inPenalty ? 'IN_PENALTY' : 'REFUNDABLE';
        roomRate._rateType = ([roomRate.priceComposition?.markup && 'MARKUP', roomRate.ratePlan && roomRate.ratePlan.type === 'COMM' && 'COMM', !roomRate.priceComposition?.markup && roomRate.ratePlan && roomRate.ratePlan.type === 'NET' && 'NET'].filter(x => x) as Array<'MARKUP' | 'COMM' | 'NET'>);
        roomRate.roomType = roomRate?.roomType && {
          ...roomRate?.roomType,
          name: roomRate?.roomType?.name?.toUpperCase()
        };
        roomRate._meal = roomRate?.meal && (roomRate?.meal.breakfast || roomRate.meal.lunch || roomRate.meal.dinner) ? normalizeMealName(roomRate?.meal.name) : null;
        roomRate._acceptedPayments = roomRate.engineRuleVersion == '1' ? roomRate.paymentOptions?.filter(op => op?.enabled != false)?.map(p => p.type) : roomRate.payment?.paymentOptions?.filter(op => op?.enabled != false)?.map(p => p.type);
        roomRate._outputPaymentTypes = roomRate.engineRuleVersion == '1' ? [] //Não determinar o tipos de pagamento ao fornecedor nessa versão #167340
        : roomRate.payment?.outputOptions?.filter(op => op?.enabled != false)?.map(p => p.type);

        //Setando o promotion(caso exista) no array discountBreakdown
        if (roomRate?.promotion) {
          const {
            promotion
          } = roomRate;
          if (roomRate?.priceComposition) {
            if (!roomRate?.priceComposition?.discountBreakdown) {
              roomRate.priceComposition.discountBreakdown = [];
            }
            roomRate.priceComposition.discountBreakdown.push({
              originType: 'PROMOCODE',
              currency: promotion?.currency,
              value: promotion?.total,
              description: promotion?.description,
              code: promotion?.code
            });
          }
        }
        return roomRate;
      });
    }
  });
  if (!result._id && result.hotel?.id) {
    result._id = result.hotel.id;
  }

  // result.omniHotelId #178496
  if (!result.omniHotelId && result._id?.startsWith('HOTEL_OMNI_')) {
    result.omniHotelId = result._id;
  }
  if (result.hotel && result._id) {
    // monta result._hotelByHotelId
    result._hotelByHotelId = result._hotelByHotelId ?? {};
    result._hotelByHotelId[result._id] = result.hotel;
  }
  if (result.hotel?.soupIds || result.hotel?.id) {
    const soupIds = _uniq([result.hotel.id].concat(result.hotel.soupIds).filter(Boolean)); // garante o próprio hotel id no soupIds
    if (!isSame(soupIds, result._soupIds)) {
      result._soupIds = soupIds;
    }
  }
  if (oldHR) {
    const preferredHotel = [oldHR.hotel, hotelResult.hotel].sort((a, b) => a?.id?.startsWith('HOTEL_OMNI_') ? -1 : 0)?.[0]; // se um dos resultados é da Omnibees, dá preferência ao da Omnibees #178496
    result = {
      ...oldHR,
      ...result,
      _id: oldHR._id,
      hotel: preferredHotel,
      omniHotelId: oldHR.omniHotelId ?? result.omniHotelId,
      _hotelByHotelId: result._hotelByHotelId && oldHR._hotelByHotelId ? Object.assign(oldHR._hotelByHotelId || {}, result._hotelByHotelId || {}) : oldHR._hotelByHotelId ?? result._hotelByHotelId
    };
    if (!shallowEqual(oldHR._soupIds, result._soupIds)) {
      result._soupIds = _uniq((oldHR._soupIds || []).concat(result._soupIds || []));
    }
    if (hotelResult.roomRates) {
      result.roomRates = [...(oldHR.roomRates || []).filter(or => (hotelResult.roomRates as Array<RoomRate | OriginalRoomRate>).filter(nr => or.id === nr.id).length === 0), ...result.roomRates];
    }
    //join no hotel details
    const _hotelDetails: HotelDetails = [oldHR, result].map(c => c && '_hotelDetails' in c && c._hotelDetails).filter(x => x).reduce((acc, v) => !acc ? v : Object.assign({
      images: !acc.images ? v.images : !v.images ? acc.images : acc.images.concat(v.images),
      guestRooms: !acc.guestRooms ? v.guestRooms : !v.guestRooms ? acc.guestRooms : acc.guestRooms.concat(v.guestRooms)
    }, v, acc), null);
    result._hotelDetails = _hotelDetails;
  }
  if (params?.referencePoint && result._rawDistance == null && result.hotel?.position) {
    //calcula distância linha reta entre hotel e ponto de referência
    const {
      latitude: lat1,
      longitude: lng1
    } = result.hotel.position;
    const {
      latitude: lat2,
      longitude: lng2
    } = params.referencePoint;
    result.hotel._rawDistance = Math.round(distanceLatLon(lat1, lng1, lat2, lng2));
    result._rawDistance = result.hotel._rawDistance;
  }
  if (result.roomRates?.length > 0 && result.roomRates !== oldHR?.roomRates) {
    //calcula e coloca em result propriedades como _bestPrice, roomRatesGrouped, etc
    const bestPrices = analyzeBestRoomRates(result.roomRates);
    Object.assign(result, bestPrices);
  }
  if (result.notAvailableRoomRates?.length > 0 && result.notAvailableRoomRates !== oldHR?.notAvailableRoomRates) {
    const notAvailableRoomRatesGrouped = groupRoomRates(result.notAvailableRoomRates);
    result.notAvailableRoomRatesGrouped = notAvailableRoomRatesGrouped;
  }
  if (params?.entitiesData?.properties) {
    result.neighborhood = params.entitiesData.properties[result.hotel.propertyId]?.neighborhood ?? null;
  }
  result._boost = result?.hotel?.meta?.boost ?? Math.max(...[...result?.roomRates.map(rr => 'meta' in rr && rr?.meta?.boost).filter(x => x), 0]);

  // Preenche o texto e a logo do hotel favorito se vier na busca
  if (params && params?.entitiesData && params?.entitiesData?.favoriteHotelsRules && result?.hotel?.meta && result?.hotel?.meta?.favoriteHotelsRuleId) {
    const favoriteHotelsRuleId = result?.hotel?.meta?.favoriteHotelsRuleId;
    result.meta = {
      boost: result?._boost,
      favoriteHotelsRuleId: result?.hotel?.meta?.favoriteHotelsRuleId,
      recommendedBadge: params?.entitiesData?.favoriteHotelsRules[favoriteHotelsRuleId]?.recommendedBadge,
      recommendedText: params?.entitiesData?.favoriteHotelsRules[favoriteHotelsRuleId]?.recommendedText
    };
  }
  return (result as HotelResult);
}
export const analyzeBestRoomRates = (roomRates: RoomRate[]): {
  _bestRemuneration: number;
  _bestRemunerationRoomRates: RoomRate[];
  _bestRemunerationRoomRate: RoomRate;
  _bestWithBreakfastRoomRates: RoomRate[];
  _bestWithBreakfastRoomRate: RoomRate;
  _bestRefundableRoomRates: RoomRate[];
  _bestRefundableRoomRate: RoomRate;
  _bestRemunerationValue: number;
  _bestRemunerationValueRoomRates: RoomRate[];
  _bestRemunerationValueRoomRate: RoomRate;
  roomRates: RoomRate[];
  roomRatesGrouped: RoomRate[][]; // TODO: refactoring para não usar mais este valor
  _bestPriceRoomRates: RoomRate[];
  _bestPriceRoomRate: RoomRate;
  _bestPrice: number;
  _bestPriceNight: number;
} => {
  let _bestRemuneration: number, _bestRemunerationRoomRates: RoomRate[], _bestRemunerationRoomRate: RoomRate, _bestWithBreakfastRoomRates: RoomRate[], _bestWithBreakfastRoomRate: RoomRate, _bestRefundableRoomRates: RoomRate[], _bestRefundableRoomRate: RoomRate, _bestRemunerationValue: number, _bestRemunerationValueRoomRates: RoomRate[], _bestRemunerationValueRoomRate: RoomRate;
  roomRates = roomRates ? [...roomRates].sort(roomRateSorter) : [];
  const roomRatesGrouped = groupRoomRates(roomRates);
  const _bestPriceRoomRates = roomRatesGrouped[0];
  const _bestPriceRoomRate = _bestPriceRoomRates && _bestPriceRoomRates[0];
  const _bestPrice = _bestPriceRoomRate?.priceComposition?.total?.value;
  const _bestPriceNight = _bestPriceRoomRate?.priceComposition?.nights?.[0]?.value;

  //buscar melhor comissão/markup
  for (const roomRateGroup of roomRatesGrouped) {
    const roomRate = roomRateGroup[0];
    const remuneration = roomRate?._remuneration;
    if (remuneration?.percentage - (_bestRemuneration || 0) > 0.001) {
      _bestRemuneration = remuneration.percentage;
      _bestRemunerationRoomRate = roomRate;
      _bestRemunerationRoomRates = roomRateGroup;
    }
    if (remuneration?.value - (_bestRemunerationValue || 0) > 0.01) {
      _bestRemunerationValue = remuneration.value;
      _bestRemunerationValueRoomRate = roomRate;
      _bestRemunerationValueRoomRates = roomRateGroup;
    }
  }
  if (_bestRemuneration === 0) {
    _bestRemuneration = undefined;
    _bestRemunerationRoomRate = undefined;
    _bestRemunerationValue = undefined;
    _bestRemunerationValueRoomRate = undefined;
  }
  //busca melhor preço com café da manhã
  for (const roomRateGroup of roomRatesGrouped) {
    const roomRate = roomRateGroup[0];
    if (roomRate?.meal?.breakfast) {
      _bestWithBreakfastRoomRate = roomRate;
      _bestWithBreakfastRoomRates = roomRateGroup;
      break;
    }
  }
  //busca melhor preço reembolsável
  for (const roomRateGroup of roomRatesGrouped) {
    const roomRate = roomRateGroup[0];
    if (roomRate.cancelPolicy && !roomRate.cancelPolicy.nonRefundable && !roomRate.cancelPolicy.inPenalty) {
      _bestRefundableRoomRate = roomRate;
      _bestRefundableRoomRates = roomRateGroup;
      break;
    }
  }
  return {
    roomRates,
    roomRatesGrouped,
    _bestRemuneration,
    _bestRemunerationRoomRates,
    _bestRemunerationRoomRate,
    _bestPriceRoomRates,
    _bestPriceRoomRate,
    _bestPrice,
    _bestPriceNight,
    _bestWithBreakfastRoomRates,
    _bestWithBreakfastRoomRate,
    _bestRefundableRoomRates,
    _bestRefundableRoomRate,
    _bestRemunerationValue,
    _bestRemunerationValueRoomRates,
    _bestRemunerationValueRoomRate
  };
};