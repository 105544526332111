import { shallowClientDAO, currentTenantDAO } from '@niarab2c/frontend-commons/src/daos';
import { ShallowClient, CurrentTenant } from '@niarab2c/niara-spear-crudmodel/src';
import type { Storefront } from '@niarab2c/otabuilder-crudmodel/src';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BaseRootState } from '../base';
import { setThemeColors } from './theme';
import * as Authentication from './authentication';
import * as Core from './core';
import * as ShoppingCart from './shoppingCart';
import * as Hotel from './hotel';
export type StorefrontWithTenantId = Storefront & {
  tenantId: string;
};
export interface StorefrontConfigState {
  storefront?: StorefrontWithTenantId;
  engineRuleVersion: '0' | '1';
}
export const storefrontConfigSlice = createSlice({
  name: 'storefrontConfig',
  initialState: ({
    engineRuleVersion: '0'
  } as StorefrontConfigState),
  reducers: {},
  extraReducers(builder) {
    builder.addCase(initStorefront.fulfilled, (state, action) => {
      state.storefront = action.payload.storefront;
      const tenant = action.payload.tenant;
      const client = action.payload.client;
      state.engineRuleVersion = client?.engineRule_enforceVersion_deleteme != null ? client?.engineRule_enforceVersion_deleteme : tenant.distribution_disabled ? '1' : '0';
    });
  }
});
export const initStorefront = createAsyncThunk<{
  storefront: StorefrontWithTenantId;
  tenant: CurrentTenant;
  client: ShallowClient;
}, StorefrontWithTenantId, {
  state: BaseRootState;
}>('storefrontConfig/initStorefront', async (storefront, {
  getState,
  dispatch
}) => {
  dispatch(ShoppingCart.setSavestatePrefix(storefront.id));
  dispatch(Core.updateState({
    clientId: storefront?.clientId,
    tenantId: storefront?.tenantId
  }));
  await dispatch(Authentication.initAuthentication({
    clientId: storefront?.clientId,
    landingPageLocator: storefront?.locator
  }));
  const client: ShallowClient = storefront?.clientId ? await shallowClientDAO.findById(storefront?.clientId) : undefined;
  const tenant = await currentTenantDAO.findById(storefront.id);
  dispatch(Core.updateState({
    client,
    tenant
  }));
  await dispatch(Authentication.loadAuthenticationRule());
  await dispatch(Hotel.loadHotelSearchRule());
  dispatch(setThemeColors({
    primary: storefront?.themeColors_primary,
    primaryReadable: storefront?.themeColors_primaryReadable,
    navbarBg: storefront?.themeColors_navbarBg,
    navbarText: storefront?.themeColors_navbarText,
    footerBg: storefront?.footer_bgColor,
    footerText: storefront?.footer_textColor
  }));
  return {
    storefront,
    client,
    tenant
  };
});
export default storefrontConfigSlice.reducer;