import { loop } from '@niaratech/niara-js-commons';
import React, { useMemo } from 'react';
import { BsCart2 } from 'react-icons/bs';
import Count from '../Count';
import * as S from './styles';
export type SelectButtonProps = {
  bgColorButton?: string;
  colorTextButton?: string;
  block?: boolean;
  onValue?: (value: number) => void;
  value?: number;
  maxValue: number;
  highlighted?: boolean | any;
  dataTestId?: string;
  typeCounter?: boolean;
};
const SelectButton: React.FC<SelectButtonProps> = ({
  value,
  maxValue,
  onValue,
  bgColorButton,
  colorTextButton,
  block,
  highlighted,
  dataTestId,
  typeCounter
}) => {
  const filled = value === 0 || value === null;
  const roomOptions: Array<{
    value;
    label;
  }> = useMemo(() => {
    return loop(maxValue + 1, i => ({
      value: i,
      label: i === 0 ? 'Nenhum quarto ' : i > 1 ? `${i} quartos` : `${i} quarto`
    }));
  }, [maxValue]);
  const max = roomOptions.map(i => i.value).length - 1;
  if (filled) {
    return <>
        <S.Btn disableElevation highlighted={highlighted ? 'highlighted' : highlighted} wide={block ? 'wide' : 'normal'} disabled={!(maxValue > 0)} size="small" fullWidth onClick={() => onValue(maxValue > 0 ? 1 : maxValue)} data-testid={dataTestId} {...bgColorButton && {
        bgColorButton: bgColorButton
      }} {...colorTextButton && {
        colorTextButton: colorTextButton
      }}>
          {
          // #178842 - Alterar o label do botão para adicionar itens no carrinho para visão simplificada
        }
          <>
            <div>
              <BsCart2 size={16} />
            </div>
            Adicionar
          </>
        </S.Btn>
      </>;
  }
  return <S.Container>
      {!typeCounter && <>
          {/* versão desktop */}
          <S.Select wide={block ? 'wide' : 'normal'} value={value} onChange={e => onValue(parseInt(e.target.value))}>
            {roomOptions.map(item => <option value={item.value} key={item.value}>
                {item.label}
              </option>)}
          </S.Select>
        </>}

      {/* versão mobile */}
      <S.Counter typeCounter={typeCounter}>
        <div>
          {!typeCounter && <S.TextCounter>N° de quartos</S.TextCounter>}
          <Count name="nome" min={0} max={max} value={value} onChange={onValue} />
        </div>
      </S.Counter>
    </S.Container>;
};
export default SelectButton;