import styled, { css } from 'styled-components'

type PaymentInfoProps = {
  justifyContent?: string
}
export const PaymentInfo = styled.div<PaymentInfoProps>`
  align-items: center;
  display: flex;
  gap: 5px;
  ${({ justifyContent }) => `justify-content: ${justifyContent}};`}
  margin-top: 5px;
  width: 100%;

  b {
    flex: 0;
  }
`

export const ConditionsContainer = styled.div`
  padding: 10px;
  max-width: 350px;
  max-height: 450px;
  overflow-y: auto;
  justify-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 15px;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme?.colors?.gray6};
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme?.colors?.gray5};
  }
  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme?.colors?.gray1};
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
`

type IconInfoProps = {
  centered?: boolean
}
export const IconInfo = styled.div<IconInfoProps>`
  display: flex;
  cursor: pointer;
  /* height: 100%; */
  color: ${({ theme }) => theme?.colors?.textColor};
  ${({ centered }) =>
    centered &&
    css`
      align-items: center;
      justify-content: center;
    `}
`
export const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`

export const Text = styled.p`
  font-family: 'Manrope';
  font-size: 12px;
  line-height: normal;
  margin: 0;
`

export const TextDescription = styled.p`
  color: ${({ theme }) => theme?.colors?.white};
  font-family: 'Manrope';
  font-size: 12px;
  line-height: normal;
  margin: 0;
  padding-bottom: 10px;
`

export const CardIcons = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 5px;
  margin-top: 10px;
  width: 100%;
`

export const TypePaymentVertical = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const TypeOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: calc(100% - 14px);
  flex: 1;
`

export const UnicTypes = styled.div`
  display: flex;
  gap: 5px;
`

type TextTypePaymentProps = {
  ellipsis?: boolean
}
export const TextTypePayment = styled.p<TextTypePaymentProps>`
  color: ${({ theme }) => theme?.colors?.text};
  font-family: 'Manrope';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;

  ${({ ellipsis }) =>
    ellipsis &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 80%;
    `}
`

export const PaymentLine = styled.div<{
  textSize?: number
  textColor?: string
  fontWeight?: number | string
  ellipsis?: boolean
}>`
  align-items: center;
  color: ${({ theme, textColor }) => textColor || theme?.colors?.text};
  display: flex;
  font-size: ${({ textSize }) => (textSize ? `${textSize}px` : '12px')};
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};

  ${({ ellipsis }) => {
    if (ellipsis) {
      return css`
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      `
    }
  }}
`

export const ToolTipContainer = styled.div`
  padding: 10px;
`

export const MorePayments = styled.p<{ textSize?: number }>`
  color: ${({ theme }) => theme?.colors?.text};
  font-weight: 700;
  font-size: ${({ textSize }) => (textSize ? `${textSize}px` : '12px')};
  margin: 0;
`
