import React from 'react';
import { ThemeColor } from '../../theme';
import { Container } from './styles';
export interface BadgeProps {
  color: ThemeColor | string;
  colorText?: ThemeColor | string;
  fontWeight?: string;
  borderRadius?: string;
  borderRadiusMb?: string;
  fullWidthMobile?: boolean;
  fullWidthTablet?: boolean;
  height?: string;
  dataTestId?: string;
  gap?: string;
  padding?: string;
}
const Badge: React.FC<React.PropsWithChildren<BadgeProps>> = ({
  borderRadius,
  borderRadiusMb,
  color,
  colorText,
  children,
  fontWeight,
  fullWidthMobile,
  fullWidthTablet,
  height,
  dataTestId,
  gap,
  padding
}) => {
  return <Container $borderRadius={borderRadius} $borderRadiusMb={borderRadiusMb} $color={color} $colorText={colorText} $fontWeight={fontWeight} $fullMobile={fullWidthMobile} $fullTablet={fullWidthTablet} $height={height} data-testid={dataTestId} $gap={gap} $padding={padding}>
      {children}
    </Container>;
};
export default Badge;